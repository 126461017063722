import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';

const CarCardHelper = defineComponent({
    name:'CarCard',
    title:'车辆',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            addStatus:false,
            selfCarFlag:false,
            showUpload:false,
            cysData:[],
            cxData:[],
            driverData:[],
            fleetData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/car"
            },
            //表单
            form:{},
            formLabelWidth:'150px',
            //表单验证规则
            rules: {
                cysId: [
                    {required: true, message: "请选择承运商", trigger: "blur" }
                ],
                fleetId: [
                    {required: true, message: "请选择关联车队", trigger: "blur" }
                ],
                cooperationType: [
                    {required: true, message: "请选择合作形式", trigger: "blur" }
                ],
                cxId: [
                    {required: true, message: "请选择车型", trigger: "blur" }
                ],
                carNum: [
                    {required: true, message: "请输入车牌号", trigger: "blur" }
                ],
                owner: [
                    {required: true, message: "请输入所有人", trigger: "blur" }
                ],
                njTime: [
                    {required: true, message: "请选择年检时间", trigger: "blur" }
                ],
                bxTime: [
                    {required: true, message: "请选择保险时间", trigger: "blur" }
                ]
            }
        })
        onMounted(()=>{

        })
        //---------------------------computed---------------------------

        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'cysId'==params.comboId){
                    return dataObj.cysData;
                }
                if(params && 'fleetId'==params.comboId){
                    return dataObj.fleetData;
                }
                if(params && 'driverId'==params.comboId){
                    return dataObj.driverData;
                }
                if(params && 'cxId'==params.comboId){
                    return dataObj.cxData;
                }

                if(params && 'cooperationType'==params.comboId){
                    return [{value:0,label:'自有车辆'},{value:1,label:'合同车辆（速享所有）'}]
                }
            }
        })
        const buildCysData=async ()=>{
            dataObj.cysData= await utils.$$api.getCysByChannelId({});
        }
        const buildDriverData=async (fleetId)=>{
            dataObj.driverData= await utils.$$api.getDriversByFleet({fleetId:fleetId});
        }
        const buildFleetData=async (cysId)=>{
            dataObj.fleetData= await utils.$$api.getFleetsByCys({cysId:cysId});
        }
        const buildCxData=async (params)=>{
            dataObj.cxData= await utils.$$api.getCx(params);
        }

        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId)=>{
            if('cysId'==selectId){
                dataObj.form.cityId='';
                dataObj.form.fleetId='';
                await buildFleetData(newValue);
            }
            if('fleetId'==selectId){
                dataObj.form.cityId='';
                dataObj.fleetData.forEach(item=>{
                    if(item.value==newValue)dataObj.form.cityName=item.cityName;
                })
                await buildDriverData(newValue);
            }
            if('driverId'==selectId){}
            if('cooperationType'==selectId){}
            if('cxId'==selectId){}


        }
        const buildUploadInitParams=computed(()=>{
            return (params) => {
                return {
                    formId:dataObj.form.id,
                    saveType:1,
                    listType:'picture-card',
                    showFileList:true,
                    showInDialog:true,
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadModule:'car',
                    limitNum:1,
                    uploadType:params.uploadType
                }
            }
        })
        const disableUpload=()=>{
            dataObj.refMap.get('xsz').disableUpload(true);
            dataObj.refMap.get('clbx').disableUpload(true);
            dataObj.refMap.get('clzp').disableUpload(true);
        }
        const setParam=(cardEngineInst)=>{
            let engineParams=proxy.engine.engineParams;
            //如果是运力配置打开车辆列表，再由车辆列表打开车辆卡片，需要把from传入后台，让后台把按钮去掉
            if(engineParams.queryParam.from){
                dataObj.disabled=true;
                disableUpload();
                return {from:engineParams.queryParam.from}
            }else{
                return{};
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            await buildCysData();
            await buildCxData();
            if('/add'==addOrLoad){
                dataObj.addStatus=true;
            }else {
                if(data.data.status==0)dataObj.disabled=false;
                if(data.data.status==2 || data.data.status==3)dataObj.disabled=true;
                dataObj.showUpload = true;
                await buildFleetData(data.data.cysId);
                await buildDriverData(data.data.fleetId);

                if(data.data.status==2 || data.data.status==3 || data.disabled){
                    dataObj.disabled=true;
                    disableUpload();
                }
                if(dataObj.form.cooperationType==0){
                    dataObj.selfCarFlag=true;
                }
            }
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'xsz',files:data.data.xszList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'xszFm',files:data.data.xszFmList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'clbx',files:data.data.clbxList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'sybxd',files:data.data.sybxdList});
            utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'clzp',files:data.data.clzpList});
        }
        //-----------------------------upload---------------------------
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,uploadInst)=>{
            if((uploadInst.uploadParams.saveType)==0){
                //不采用第三方文件服务器，formData才有append方法，否则formData就是一个普通的对象，没有append方法
                formData.append('belongMaxId',dataObj.form.id);
            }else{
                formData.belongMaxId=dataObj.form.id;
            }
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
        }

        //-----------------------------upload---------------------------
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const afterSaveHandler=(res,contentName,engineInst)=>{
            if(res.data.status==2 || res.data.status==3){
                dataObj.disabled=true;
            }else{
                dataObj.disabled=false;
            }

            dataObj.refMap.get('xsz').disableUpload(dataObj.disabled);
            dataObj.refMap.get('clbx').disableUpload(dataObj.disabled);
            dataObj.refMap.get('clzp').disableUpload(dataObj.disabled);
            return true;
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const channelClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sassClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,setParam,afterSaveHandler,
            startHandler,stopHandler,buildUploadInitParams,afterResult,buildUploadParams,channelClaimHandler,sassClaimHandler,sureHandler
         
        }
    }
});
export default CarCardHelper;