<template>
    <div class="Car">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="承运商" prop="cysId" :label-width="formLabelWidth">
                        <el-select :ref="el=>refMap.set('cysId',el)" v-model="form.cysId" clearable placeholder="所属承运商" style="width: 100%" :disabled="disabled || selfCarFlag" @change="(val)=>{selectOnChange(val,'cysId')}">
                            <el-option v-for="item in cysData" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="关联车队" prop="fleetId" :label-width="formLabelWidth">
                        <el-select :ref="el=>refMap.set('fleetId',el)" v-model="form.fleetId" clearable placeholder="请选择关联车队" style="width: 100%" :disabled="disabled || selfCarFlag" @change="(val)=>{selectOnChange(val,'fleetId')}">
                            <el-option v-for="item in comboSelect({comboId:'fleetId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                {{ item.label.length>15?item.label.substr(0,15)+'...':item.label }}&nbsp;&nbsp;{{ item.yqm }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
<!--                <el-col :span="12">-->
<!--                    <el-form-item label="城市" prop="cityId" :label-width="formLabelWidth">-->
<!--                        {{form.cityName}}-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="12">
                    <el-form-item label="默认司机" prop="driverId" :label-width="formLabelWidth">
                        <el-select :ref="el=>refMap.set('driverId',el)" v-model="form.driverId" clearable placeholder="请选择默认司机" style="width: 100%" :disabled="disabled || selfCarFlag" @change="(val)=>{selectOnChange(val,'driverId')}">
                            <el-option v-for="item in comboSelect({comboId:'driverId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="所有人" prop="owner" :label-width="formLabelWidth">
                        <el-input @input="e => form.owner = validForbid(e)" v-model="form.owner" placeholder="请输入所有人" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="合作形式" prop="cooperationType" :label-width="formLabelWidth">
                        <el-select :ref="el=>refMap.set('cooperationType',el)" v-model="form.cooperationType" clearable placeholder="请选择合作形式" style="width: 100%" :disabled="disabled || selfCarFlag" @change="(val)=>{selectOnChange(val,'cooperationType')}">
                            <el-option v-for="item in comboSelect({comboId:'cooperationType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车型" prop="cxId" :label-width="formLabelWidth">
                        <el-select :ref="el=>refMap.set('cxId',el)" v-model="form.cxId" clearable placeholder="请选择车型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cxId')}">
                            <el-option v-for="item in comboSelect({comboId:'cxId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车牌号" prop="carNum" :label-width="formLabelWidth">
                        <el-input @input="e => form.carNum = validForbid(e)" v-model="form.carNum" placeholder="请输入车牌号" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="年检时间" prop="njTime" :label-width="formLabelWidth">
                        <el-date-picker v-model="form.njTime" placeholder="请选择年检时间" :disabled="false"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="保险时间" prop="bxTime" :label-width="formLabelWidth">
                        <el-date-picker v-model="form.bxTime" placeholder="请选择保险时间" :disabled="false"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车架号" prop="vin" :label-width="formLabelWidth">
                        <el-input @input="e => form.vin = validForbid(e)" v-model="form.vin" placeholder="请输入车架号" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="道路运输许可证" prop="xkz" :label-width="formLabelWidth">
                        <el-input @input="e => form.xkz = validForbid(e)" v-model="form.xkz" placeholder="请输入道路运输许可证" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row v-show="!addStatus">
                <el-col :span="12">
                    <el-form-item label="是否停用" :label-width="formLabelWidth">
                        <span>{{form.flag==0?'启用':'禁用'}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="创建时间" :label-width="formLabelWidth">
                        <span>{{form.time}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div v-show="showUpload" style="margin-top: 20px">
        <el-row>
            <el-col :span="2">行驶证正面</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'xsz'})" :ref="el=>refMap.set('xsz',el)"></Upload>
            </el-col>
            <el-col :span="2">行驶证反面</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'xszFm'})" :ref="el=>refMap.set('xszFm',el)"></Upload>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="2">强制保险单</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'clbx'})" :ref="el=>refMap.set('clbx',el)"></Upload>
            </el-col>
            <el-col :span="2">商业保险单</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'sybxd'})" :ref="el=>refMap.set('sybxd',el)"></Upload>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="2">车辆照片</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'clzp'})" :ref="el=>refMap.set('clzp',el)"></Upload>
            </el-col>
        </el-row>
    </div>
</template>


<script>
    import CarCardHelper from "./CarCardHelper.js";
    export default CarCardHelper;
</script>

<style scoped>
    .Car{
        width: 100%;
    }
</style>
